<template>
    <popup-dialog-base
        ref="Popup"
        title="Wähle eine Variable"
        :tool-buttons="[]"
        :persistent="false"
    >
        <template v-slot:content>
            <v-card-text
                v-if="variableKeys"
            >
                <v-text-field 
                    v-model="searchFilter"
                    dense
                    solo hide-details
                    prepend-inner-icon="mdi-magnify"
                    class="mb-2"
                    clearable
                />
                <div
                    v-for="(arr, key) in filteredVariableKeys" :key="key"
                >
                    <div
                        v-if="filteredVariableKeys[key].length > 0"
                    >

                        
                        
                        <v-card class="mb-1">
                            <v-card-text class="ma-0 pa-0 pr-1 pl-1">
                                <h3 class="text-capitalize"> <v-icon>mdi-code-braces-box</v-icon>{{ key }}-Variablen</h3>
                                <v-chip-group
                                    column
                                    v-if="filteredVariableKeys[key].length > 0"
                                >
                                    <v-chip
                                        label
                                        small
                                        v-for="(chipName, index) in filteredVariableKeys[key]" :key="index"
                                        @click="selectVariable(chipName)"
                                    >
                                        {{ chipName }}
                                    </v-chip>
                                </v-chip-group>
                            </v-card-text>
                        </v-card>
                    </div>
                </div>
            </v-card-text>
        </template>

    </popup-dialog-base>
</template>

<script>
import PopupDialogBase from '../PopupDialogBase.vue';
export default {
    components:{
        PopupDialogBase
    },
    inject:["dashboard"],
    props:{
        year:{type:Number},
    },
    computed:{
        filteredVariableKeys(){
            let filteredVariables = {}
            for(let key in this.variableKeys){
                if(!filteredVariables[key]){
                    filteredVariables[key] = []
                }
                for(let variable of this.variableKeys[key]){
                    if(this.searchFilter === "" || variable.toLowerCase().includes(this.searchFilter.toLowerCase())){
                        filteredVariables[key].push(variable)
                    }
                }
            }
            return filteredVariables;
        }
    },
    data(){
        return {
            searchFilter:"",
            variableKeys:{
                berechnete:["kosten_gesamt", "freizeit_kosten"],
                camp:[],
                teilnehmer:[],
                mitarbeiter:[],
            },
            excpludedKeys:[
                "teilnehmer_anmeldung_countdown",
                "id"
            ]
        }
    },
    methods:{
        show(){
            this.searchFilter = "";
            this.$refs.Popup.show();
            this.generateVariableKeys();
        },
        hide(){
            this.$refs.Popup.hide();
        },
        async generateVariableKeys(){
            // camp variables
            this.variableKeys.camp = []
            let result = await this.$network.requestGet("/fields/camp")
            if(result.status === 200){
                for(let field of result.data.data){ 
                    if(field.meta.interface === "input" || field.meta.interface === "datetime"){
                        if(!this.excpludedKeys.includes(field.field)){
                            this.variableKeys.camp.push(field.field);        
                        }
                    }
                }
            }
            
            // teilnehmer variables
            if(this.dashboard.componentProperties.collection === undefined || this.dashboard.componentProperties.collection === "teilnehmer_"){
                this.variableKeys.teilnehmer = ["freizeit_datum"];
                let collection = "teilnehmer_" + this.dashboard.componentProperties.year
                result = await this.$network.requestGet("/fields/"+collection)
                if(result.status === 200){
                    for(let field of result.data.data){ 
                        if(!this.excpludedKeys.includes(field.field)){
                            if(field.meta.interface === "input" || 
                                field.meta.interface === "numeric" || 
                                field.meta.interface === "datetime" ||
                                field.meta.interface === "select-radio" ||
                                field.meta.interface === "select-dropdown" ||
                                field.type === "string"
                            ){
                                this.variableKeys.teilnehmer.push(field.field);        
                            }
                        }
                    }
                }
            }

            // mitarbeiter variables
            if(this.dashboard.componentProperties.collection === undefined || this.dashboard.componentProperties.collection === "mitarbeiter_"){
                this.variableKeys.mitarbeiter = [];
                let collection = "mitarbeiter_" + this.dashboard.componentProperties.year
                result = await this.$network.requestGet("/fields/"+collection)
                if(result.status === 200){
                    for(let field of result.data.data){ 
                        if(!this.excpludedKeys.includes(field.field)){
                            if(field.meta.interface === "input" || 
                                field.meta.interface === "numeric" || 
                                field.meta.interface === "datetime" ||
                                field.meta.interface === "select-radio" ||
                                field.meta.interface === "select-dropdown" ||
                                field.type === "string"
                            ){
                                this.variableKeys.mitarbeiter.push(field.field);        
                            }
                        }
                    }
                }
            }
            
        },
        selectVariable(name){
            this.$emit("onVariableSelected", name)
            this.hide()
        }
    }
}
</script>