<template>
    <report-card-base
        ref="report"
        :title="title"
        :icon="'mdi-form-select'"
    >
        <template v-slot:header-tools>
            <v-btn
                icon
                x-small
                @click="printCards"
            >
                <v-icon>
                    mdi-card-text-outline
                </v-icon>
            </v-btn>
        </template>

        <template v-slot:content>
            <bar-chart
                v-if="registrationChartData"
                :height="150"
                :chartData="registrationChartData"
            />
            <v-simple-table
                dense
                v-if="data"
            >
                <tbody>
                    <tr v-for="(item, index) in data" :key="index"
                        :class="index%2===0 ? 'striped' : ''"
                    >   
                        <td>
                            <strong>
                                {{item.rowLabel}} 
                            </strong>
                        </td>
                        <td>
                            <v-tooltip bottom
                                v-if="item.rowTooltip.length>0"
                            >   
                                <p class="capitalize ma-0" v-for="(name, index) in item.rowTooltip" :key="index">
                                    {{name}}
                                </p>
                                <template v-slot:activator="{ on, attrs}">

                                    <span v-bind="attrs" v-on="on">
                                        {{item.rowValue}}
                                        <v-icon class="mb-3" :color="item.color?item.color:'primary'" size="16">mdi-information</v-icon>
                                    </span>
                                </template>
                            </v-tooltip>
                            <span v-else>
                                {{item.rowValue}}
                            </span>
                        </td>
                    </tr>
                </tbody>
            </v-simple-table>
        </template>
    </report-card-base>
</template>

<script>
import dayjs from 'dayjs'
import ReportCardBase from './ReportCardBase.vue'
import BarChart from '@/components/chart/BarChart.vue'
require('dayjs/locale/de')

export default {
    components:{
        ReportCardBase,
        BarChart
    },
    async mounted(){
        
        if(this.$refs.report){
            this.$refs.report.setLoading(true);
        }
        await this.getCollectionFilter();
        await this.requestData();
        await this.getFilterData();
        
        if(this.$refs.report){
            this.$refs.report.setLoading(false);
        }
    },
    props:{
        collection:String,
        title:String,
        year:String,
        chartTitle:String,
        cardSettings:{type:Object, default:()=>{}}
    },
    data(){
        return {
            data:[],
            dataRaw:undefined,
            campData:undefined,
            registrationChartData:undefined,
            filters:undefined,
        }
    },
    methods:{
        async getCollectionFilter(){
            const collection = this.collection.split(this.year)[0];
            let url = `/items/berichte_filter?limit=-1&sort=sort`
            let result = await this.$network.requestGet(url);
            if(result.status === 200){
                let filters = [];
                for(let filter of result.data.data){
                    if(filter.collection && filter.collection.includes(collection)){
                        filters.push(filter);
                    }
                }
                this.filters = filters;
            }
        },
        async getFilterData(){
            if(!this.filters) return;
            for(let filter of this.filters){
                let url = `/items/${this.collection}`;
                url += `?limit=-1&filter=${filter.filter.replace("\n","").replace(" ","%20")}`;
                url += `&fields=${filter.fields}`
                let result = await this.$network.requestGet(url);
                if(result.status === 200){
                    if(result.data.data){
                        let names = []

                        const evalFunction = Function("items", filter.evaluationFunction);
                        let items = evalFunction(result.data.data);

                        for(let item of items){
                            names.push(`${item.vorname} ${item.nachname}`)
                        }
                        this.data.push({
                            rowLabel:filter.name,
                            rowValue:items.length,
                            rowTooltip:filter.show_tooltip ? names : "",
                        })
                    }
                }
            }
        },
        getRegistrationChartData(data){
            let registrations = {}
            let confirmed = {}
            let queue = {}
            let cancelled = {}

            let dates = [];
            for(let person of data){
                let date = dayjs(person.status_anmeldung_datum).format("YYYY-MM-DD");
                if(!registrations[date]){
                    registrations[date] = 0;
                    if(!dates.includes(date)){
                        dates.push(date)
                    }
                }
                registrations[date] += 1;

                if(person.status_person === "Bestätigt"){
                    date = dayjs(person.status_aenderung_datum).format("YYYY-MM-DD");
                    if(!confirmed[date]){
                        confirmed[date] = 0;
                        if(!dates.includes(date)){
                            dates.push(date)
                        }
                    }
                    confirmed[date] += 1;
                }
                
                if(person.status_person === "Warteliste"){
                    date = dayjs(person.status_aenderung_datum).format("YYYY-MM-DD");
                    if(!queue[date]){
                        queue[date] = 0;
                        if(!dates.includes(date)){
                            dates.push(date)
                        }
                    }
                    queue[date] += 1;
                }
                
                if(person.status_person === "Abgemeldet" || person.status_person === "Absage"){
                    date = dayjs(person.status_aenderung_datum).format("YYYY-MM-DD");
                    if(!cancelled[date]){
                        cancelled[date] = 0;
                        if(!dates.includes(date)){
                            dates.push(date)
                        }
                    }
                    cancelled[date] += 1;
                }
            }
            dates.sort()
            let startDate = dates[0];
            let endDate = dates[dates.length -1];
            endDate = dayjs(endDate).add(1,"day").format("YYYY-MM-DD")
            dates = [];
            while(startDate <= endDate){
                dates.push(startDate);
                startDate = dayjs(startDate).add(1,"day").format("YYYY-MM-DD");
            }

            let chartData = {
                labels: [],
                datasets: [
                    {
                        label:"Angemeldet",
                        data:[],
                        borderWidth:2,
                        borderColor:this.$vuetify.theme.defaults.light.primary,
                        backgroundColor:this.$vuetify.theme.defaults.light.primary,
                    },
                    {
                        label:"Bestätigt",
                        data:[],
                        borderWidth:2,
                        borderColor:this.$vuetify.theme.defaults.light.success,
                        backgroundColor:this.$vuetify.theme.defaults.light.success
                    },
                    {
                        label:"Warteliste",
                        data:[],
                        borderWidth :2,
                        borderColor:this.$vuetify.theme.defaults.light.warning,
                        backgroundColor:this.$vuetify.theme.defaults.light.warning
                    },
                    {
                        label:"Abgemeldet",
                        data:[],
                        borderWidth :2,
                        borderColor:this.$vuetify.theme.defaults.light.error,
                        backgroundColor:this.$vuetify.theme.defaults.light.error
                    }
                ]
            }
            for(let date of dates){
                // chartData.labels.push(dayjs(date).format("DD.MM"))
                chartData.labels.push(dayjs(date).locale("de").format("dd DD.MM"))
                if(registrations[date]){
                    chartData.datasets[0].data.push(registrations[date])
                }else{
                    chartData.datasets[0].data.push(0)
                }
                if(confirmed[date]){
                    chartData.datasets[1].data.push(confirmed[date])
                }else{
                    chartData.datasets[1].data.push(0)
                }
                if(queue[date]){
                    chartData.datasets[2].data.push(queue[date])
                }else{
                    chartData.datasets[2].data.push(0)
                }
                if(cancelled[date]){
                    chartData.datasets[3].data.push(cancelled[date])
                }else{
                    chartData.datasets[3].data.push(0)
                }
            }
            return chartData;
        },
        getCampDate(campName){
            for(let camp of this.campData){
                if (camp.name === campName){
                    return camp.datum_von;
                }
            }
            return undefined;
        },
        getCampByName(campName){
            for(let camp of this.campData){
                if (camp.name === campName){
                    return camp;
                }
            }
            return undefined;
        },
        age(date, freizeit){
            let campDate = this.getCampDate(freizeit);
            return dayjs(campDate).diff(date, "year");
            
        },
        getPositionAlias(campName, position){
            let camp = this.getCampByName(campName);
            for(let campPosition of camp.mitarbeiter_positionen){
                if(campPosition.position === position){
                    return campPosition.alias;
                }
            }
            return undefined;
        },
        getPosition(person){
            let str = ""
            if(person.mitarbeiter_position){
                for(let key of Object.keys(person.mitarbeiter_position)){
                    let position = person.mitarbeiter_position[key].position;
                    if(position){
                        const alias = this.getPositionAlias(key, position)
                        const aliasStr = alias ? `<span style="font-size:12px;"> (${alias})</span>` : ""
                        str += "<b>"+key+"</b> - "+position + aliasStr + "</br>"    
                    }
                }
            }
            return str;
        },
        getChurch(person){
            let str = "<p style='font-size:14px;'>"
            if(person.gemeinde != 'andere Gemeinde'){
                str += person.gemeinde + "</br>";
            }else{
                str += person.meine_gemeinde ? person.meine_gemeinde + "</br>" : "";
            }
            str += "</p>"
            return str
        },
        sortByPosition(personA, personB){
            let freizeitA = this.getMitarbeiterFreizeit(personA);
            let freizeitB = this.getMitarbeiterFreizeit(personB);
            let a = parseInt(freizeitA.split("E")[1]);
            let b = parseInt(freizeitB.split("E")[1]);
            return a - b;
        },
        getMitarbeiterFreizeit(person){
            for(let freizeit in person.mitarbeiter_position){
                if(person.mitarbeiter_position[freizeit].position){
                    return freizeit
                }
            }
            return "";
        },
        printCards(){
            let str = ""
            str += `
                <html>
            `
            str=`<body style='-webkit-print-color-adjust: exact;'>
            `
            // str += "<h4 style='margin-bottom:5px; font-family:Arial;'>Espelcamp - Gebetskarten"

            let personArray = []
            for(let person of this.dataRaw){
                if(person.status_person === "Bestätigt"){
                    personArray.push(person);
                }
            }
            let cols = this.cardSettings.cols;
            let rows = Math.ceil(personArray.length/cols);

            if(this.cardSettings.type === 'teilnehmer'){
                str += "<table style='page-break-inside: auto;' width='100%' style='border:0px; border-collapse:collapse;'>"
            }else if(this.cardSettings.type === 'mitarbeiter'){
                personArray = personArray.sort(this.sortByPosition);
                str += "<table style='border:0px; border-collapse:collapse; page-break-inside: auto;'>"
            }
            str += "<thead>"
            str += "<tr><th></th></tr>"
            str += "</thead>"
            let idx = -1
            var freizeitPrev = "E1";
            var freizeitCurr = "E1";

            for(let row=0; row<rows; row++){
                str += `<tr style="page-break-inside:avoid; page-break-after:auto;">`
                for(let col=0; col<cols; col++){
                    freizeitPrev = freizeitCurr;
                    idx += 1
                    let person = personArray[idx];
                    if(person){

                        if(this.cardSettings.type === 'teilnehmer'){
                            freizeitCurr = person.freizeit;
                            if(freizeitCurr !== freizeitPrev){
                                col = 0;
                                str += `<td></td></tr>
                                            <tr style="page-break-after:always;"></tr>
                                        <tr style="page-break-inside:avoid; page-break-after:auto;">`
                            }
                            str += `
                            
                            <td style="text-align:center; font-family:Arial; border:1px solid #eee; height:90px; width:1000px; padding:0;">
                                
                                <div style="overflow:hidden; margin:10px;">
                                    <u><h4 style="color:#515151; margin:0; padding:0;">${person.vorname} ${person.nachname}</h4></u>
                                    <div style="font-size:10px;">
                                        ${this.age(person.geburtstag, person.freizeit)} Jahre, 
                                        Freizeit: ${person.freizeit},
                                        ${person.gemeinde?person.gemeinde:''}
                                    </div>
                                </div>
                            </td>`
                        }else if(this.cardSettings.type === 'mitarbeiter'){
                            freizeitCurr = this.getMitarbeiterFreizeit(person);
                            if(freizeitCurr !== freizeitPrev){
                                col = 0;
                                str += `<td></td></tr>
                                            <tr style="page-break-after:always;"></tr>
                                        <tr style="page-break-inside:avoid; page-break-after:auto;">`
                            }
                            str += `
                            <td style="text-align:center; font-family:Arial; border:1px solid #bbb; height:54mm; width:85mm; padding:  0;">
                                <div style="height:100%;">
                                    
                                    <div style="height:30%; padding-top:1%; background-color:#eee;">
                                        <h2 style="color:#000000;">${person.vorname} ${person.nachname}</h2>
                                    </div>
                                    <div style="color:#000000; font-size:20px; margin-top:10%;">
                                        ${this.getPosition(person)} 
                                        ${this.getChurch(person)}
                                    </div>
                                </div>
                            </td>`
                        }
                    }
                }
                str += "</tr>"
            }
            str += "</table>"
            str += "</body></html>"

            let newWin= window.open("");
            newWin.document.write(str);
            newWin.print();
            newWin.close();
        },
        async requestData(){
            let url = "/items/"+this.collection+"?limit=-1"
            url += "&sort="
            for(let i=0; i<this.cardSettings.sort.length; i++){
                let sort = this.cardSettings.sort[i];
                url += sort;
                if(i<this.cardSettings.sort.length-1){
                    url +=","
                }
            }
            let result = await this.$network.requestGet(url);
            if(result.status === 200){
                this.dataRaw = result.data.data;
                this.registrationChartData = this.getRegistrationChartData(result.data.data);
            }

            result = await this.$network.getCampData(this.year);
            this.campData = result.freizeit;
        },
    }
}
</script>

<style>
    .striped {
        background-color: rgba(0, 0, 0, .03);
    }
    .capitalize {
        text-transform: capitalize;
    }
</style>