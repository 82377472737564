<template>
    <report-card-base
        :title="'T-Shirt-Bestellungen'"
        icon="mdi-tshirt-crew"
    >
        <template v-slot:header-tools>
            <v-btn
                icon
                x-small
                @click="openPrioritizeOrdersDialog"
            >
                <v-icon>
                    mdi-asterisk
                </v-icon>
            </v-btn>
        </template>

        <template v-slot:content
            v-if="loaded && shirtData !== undefined"
        >
            <v-simple-table
                dense
            >
                <thead>
                    <tr
                    >
                        <th></th>
                        <th
                            v-for="(size, key2) in shirtData[getEinheitsGroesse()]" :key="key2"
                        >
                            {{key2}}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        v-for="(gender, key) in shirtData" :key="key"

                    >
                        <td>
                            <strong>{{key}}</strong>
                        </td>
                        <td
                            v-for="(size, key2) in gender" :key="key2"
                        >
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <span v-bind="attrs" v-on="on">
                                        <span v-if="(size.count > 0)">
                                            <b v-if="key2 === 'Gesamt'">
                                                {{size.count}}
                                            </b>
                                            <span v-else>
                                                {{size.count}}
                                            </span>
                                        </span>
                                        <span v-else>
                                            -
                                        </span>
                                    </span>
                                </template>
                                <v-row
                                    no-gutters
                                    class="mb-1"
                                    v-for="(name, index) in size.names" :key="index"
                                >
                                    <v-col>
                                        {{name}}
                                    </v-col>
                                </v-row>
                            </v-tooltip>
                            
                        </td>
                    </tr>
                </tbody>
            </v-simple-table>
            
            <confirm-dialog
                ref="prioOrders"
                title="Priorisieren"
                text="Aktuelle Bestellungen werden jetzt priorisiert. Mitarbeiter und Teilnehmer die eine Tshirt-Bestellung haben werden extra vermerkt."
                :onConfirm="prioritizeOrders"
            />
        </template>
    </report-card-base>
</template>

<script>
import ReportCardBase from './ReportCardBase.vue';
import ConfirmDialog from '../../../components/ConfirmDialog.vue';
export default {
    components:{
        ReportCardBase,
        ConfirmDialog
    },
    props:{
        year:{type:String}
    },
    data(){
        return{
            shirtData:undefined,
            loaded:false,
            employeeIds:[],
            participantIds:[]
        }
    },
    async created(){
        await this.getData();
    },
    methods:{
        openPrioritizeOrdersDialog(){
            this.$refs.prioOrders.show();
        },
        async prioritizeOrders(){
            let payload = {
                "keys":this.employeeIds,
                "data":{
                    "tshirt_priorisiert":true
                }
            }
            let url = "/items/mitarbeiter_"+this.year
            await this.$network.requestPatch(url,payload);
            payload = {
                "keys":this.participantIds,
                "data":{
                    "tshirt_priorisiert":true
                }
            }
            url = "/items/teilnehmer_"+this.year
            await this.$network.requestPatch(url,payload);
            
        },
        sortDictionary(dict) {
            return Object.keys(dict).sort((a, b)=>{
                let sortWeights = {
                    "4XL":10,
                    "3XL":9,
                    "2XL":8,
                    "XL":7,
                    "L":6,
                    "M":5,
                    "S":4,
                    "12/14":3,
                    "9/11":2,
                }
                let aWeighted = sortWeights[a] ? sortWeights[a]:1;
                let bWeighted = sortWeights[b] ? sortWeights[b]:1;
                if (aWeighted >= bWeighted){
                    return -1;
                }
                return 1;
            }).reduce((result, key) => {
                result[key] = dict[key];
                return result;
            }, {});
        },
        getEinheitsGroesse(){
            if(this.year >= 2025){
                return "Einheitsgröße";
            }else{
                return "Herren";
            }
        },
        getTshirtTypen(){
            if(this.year >= 2025){
                return [this.getEinheitsGroesse(),"Kinder"]
            }else{
                return [this.getEinheitsGroesse(),"Damen","Kinder"];
            }
        },
        replaceKey(key){
            switch (key){
                case "Männer":
                    return "Einheitsgröße"
                case "Frauen":
                    return "Damen"
            }
            return key
        },
        async getData(){
            let url = "/items/mitarbeiter_"+this.year;
            url += "?limit=-1";
            url += '&filter={"_and":[{"status_person":{"_eq":"Bestätigt"}}]}'
            url += "&fields=id,vorname,nachname,tshirt_typ,tshirt_groesse_herren,tshirt_groessen_damen";
            let resultEmployee = await this.$network.requestGet(url);
            this.shirtData = {
            }
            if(resultEmployee.status === 200){
                this.employeeIds = [];
                for(let person of resultEmployee.data.data){
                    this.employeeIds.push(person.id);
                    for(let key of this.getTshirtTypen()){
                        if(!this.shirtData[key]){
                            this.shirtData[key] = {}
                        }
                        if(!this.shirtData[key]["Gesamt"]){
                            this.shirtData[key]["Gesamt"] = {count:0, names:[]}
                        }
                        if(person.tshirt_groesse_herren && !this.shirtData[key][person.tshirt_groesse_herren]){
                            this.shirtData[key][person.tshirt_groesse_herren] = {count:0, names:[]}
                        }
                        if(person.tshirt_groessen_damen && !this.shirtData[key][person.tshirt_groessen_damen]){
                            this.shirtData[key][person.tshirt_groessen_damen] = {count:0, names:[]}
                        }
                    }
                }
            }
            
            url = "/items/teilnehmer_"+this.year;
            url += "?limit=-1";
            // url += "&filter[tshirt][_eq]=true"
            
            url += '&filter={"_and":[{"status_person":{"_eq":"Bestätigt"}},{"tshirt":{"_eq":true}}]}'
            url += "&fields=id,vorname,nachname,tshirt,tshirt_typ,tshirt_groesse_herren,tshirt_groesse_frauen,tshirt_groesse_kinder";
            let resultParticipants = await this.$network.requestGet(url);
            
            if(resultParticipants.status === 200){
                this.participantIds = []
                for(let person of resultParticipants.data.data){
                    this.participantIds.push(person.id);
                    for(let key of this.getTshirtTypen()){
                        if(!this.shirtData[key]){
                            this.shirtData[key] = {}
                        }
                        if(person.tshirt_groesse_herren && !this.shirtData[key][person.tshirt_groesse_herren]){
                            this.shirtData[key][person.tshirt_groesse_herren] = {count:0, names:[]}
                        }
                        if(person.tshirt_groesse_frauen && !this.shirtData[key][person.tshirt_groesse_herren]){
                            this.shirtData[key][person.tshirt_groesse_frauen] = {count:0, names:[]}
                        }
                        if(person.tshirt_groesse_kinder && !this.shirtData[key][person.tshirt_groesse_herren]){
                            this.shirtData[key][person.tshirt_groesse_kinder] = {count:0, names:[]}
                        }
                    }
                }
            }
            
            if(this.shirtData[this.getEinheitsGroesse()]){
                this.shirtData[this.getEinheitsGroesse()] = this.sortDictionary(this.shirtData[this.getEinheitsGroesse()]);
            }
            if(this.shirtData["Damen"]){
                this.shirtData["Damen"] = this.sortDictionary(this.shirtData["Damen"]);
            }
            if(this.shirtData["Kinder"]){
                this.shirtData["Kinder"] = this.sortDictionary(this.shirtData["Kinder"]);
            }

            if(resultEmployee.status === 200){
                for(let person of resultEmployee.data.data){
                    let typ = this.replaceKey(person.tshirt_typ);
                    if(person.tshirt_groesse_herren && typ === this.getEinheitsGroesse()){
                        this.shirtData[typ]["Gesamt"].count +=1;
                        this.shirtData[typ][person.tshirt_groesse_herren].count +=1
                        this.shirtData[typ][person.tshirt_groesse_herren].names.push(person.vorname + " " + person.nachname)
                    }
                    
                    if(person.tshirt_groessen_damen && typ === "Damen"){
                        this.shirtData[typ]["Gesamt"].count +=1;
                        this.shirtData[typ][person.tshirt_groessen_damen].count +=1
                        this.shirtData[typ][person.tshirt_groessen_damen].names.push(person.vorname + " " + person.nachname)
                    }
                }
            }

            
            if(resultParticipants.status === 200){
                for(let person of resultParticipants.data.data){
                    let typ = this.replaceKey(person.tshirt_typ);

                    if(person.tshirt_groesse_herren && typ === this.getEinheitsGroesse()){
                        this.shirtData[typ]["Gesamt"].count +=1;
                        this.shirtData[typ][person.tshirt_groesse_herren].count +=1;
                        this.shirtData[typ][person.tshirt_groesse_herren].names.push(person.vorname + " " + person.nachname);
                    }
                    
                    if(person.tshirt_groesse_frauen && typ === "Damen"){
                        this.shirtData[typ]["Gesamt"].count +=1;
                        this.shirtData[typ][person.tshirt_groesse_frauen].count +=1
                        this.shirtData[typ][person.tshirt_groesse_frauen].names.push(person.vorname + " " + person.nachname);
                    }
                    
                    if(person.tshirt_groesse_kinder && typ === "Kinder"){
                        this.shirtData[typ]["Gesamt"].count +=1;
                        this.shirtData[typ][person.tshirt_groesse_kinder].count +=1
                        this.shirtData[typ][person.tshirt_groesse_kinder].names.push(person.vorname + " " + person.nachname);
                    }
                }
            }
            this.loaded = true
        }
    }
}
</script>